import React from 'react'
import { Redirect } from 'react-router-dom';
import DistributorsService from '../../../services/distributors'
// import AdminTable from '../../../shared-components/admin-table';
import {AdminTable} from '@ses-education/courses-components';
import EditDistributorForm from './form';
import {
    Button,
} from "@material-ui/core";
import { AddBox, Delete, Edit } from '@material-ui/icons';
class Distributors extends React.Component{

    state={
        distributors: null,
        redirect: null,
        selectedRows: null,
        confirmDialog: false,
        modalOpen: false,
        user: null,
    }
    async componentDidMount() {
        await this.fetchDistributors();
    }
    fetchDistributors = async () => {
        const distributors = await DistributorsService.getAll();
        this.setState({distributors});
    }
    closeModal(){
        this.setState({user:null,modalOpen:null})
    }
    render(){
        const {distributors,redirect,selectedRows,modalOpen,user} = this.state;
        const columns = [
            { field: "id", headerName: "ID", flex: 1},
            { field: "title", headerName: "Title", flex: 1, renderCell: (params) =>
                <div
                    onClick={()=>this.setState({redirect:`/distributors/${params.row.id}`})}
                >
                    {params.value}
                </div>
            },
            { field: "status", headerName: "Status", flex: 1 },
            { field: "country", headerName: "Country", flex: 1 },
            { field: "users", headerName: "Users", flex: 1, renderCell: ({value}) => JSON.stringify(value) },
            { field: "clients", headerName: "Clients", flex: 1, renderCell: ({value}) => JSON.stringify(value) },
            {field: "actions", headerName: "Actions", flex: 1, renderCell: (params) =>{
                return (
                    <Button
                        onClick={() =>
                            this.setState({
                                modalOpen: true,
                                user: params.row,
                            })
                        }
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Edit />}
                    >
                        Edit
                    </Button>
                )
            }}
        ];
        const buttons = (
            <>
                <Button
                  onClick={() =>
                    this.setState({
                        modalOpen: true,
                    })
                  }
                  className="green-btn"
                  variant="contained"
                  startIcon={<AddBox />}
                >
                    Add
                </Button>
                <Button
                    onClick={() =>
                        this.setState({
                            confirmDialog: true,
                        })
                    }
                    color="secondary"
                    variant="contained"
                    disabled={!(Array.isArray(selectedRows) && selectedRows.length !== 0)}
                    startIcon={<Delete />}
                >
                    Delete
                </Button>
            </>
        );        
        if (redirect){
            return <Redirect to={redirect} />;
        }

        return(
            <>
                {distributors && (
                    <AdminTable
                        autoHeight
                        pageSize={10}
                        columns={columns}
                        rows={distributors}
                        buttons={buttons}
                        checkboxSelection
                        onSelectionModelChange={(selectedRows) => this.setState({selectedRows})}
                        disableSelectionOnClick
                    />
                )}
                {modalOpen && (
                    <EditDistributorForm
                        onModalOpen={modalOpen}
                        onModalClose={this.closeModal.bind(this)}
                        onChanges={this.fetchDistributors}
                        user={user}
                    />
                )}
            </>
        )
    }
}

export default Distributors