import React from 'react'


class DistributorUser extends React.Component{

    render(){
        const { user_id } = this.props.match.params;
        return(`${user_id}`)
    }
}

export default DistributorUser