import React from "react";
import DistributorsService from "../../../services/distributors";
import { connect } from "react-redux";
// import { showMessage } from "../../../shared-components/notifications/notificationActions";
import { showMessage } from "@ses-education/courses-components";
// import Modal from "../../../shared-components/modal-window";
import Modal from "@ses-education/courses-components";
import countries from '../../../data/countries-short.json';
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core'

class EditDistributorForm extends React.Component {
    state={
        id:'',
        title:'',
        status:'',
        country:'',
    }
    async componentDidMount() {
        const {user} = this.props;
        if (user){
            const {id,title,status,country} = user;
            this.setState({id,title,status,country});
        }
    }
    async componentDidUpdate(prevProps){
        const {user} = this.props;
        if (JSON.stringify(prevProps.user) !== JSON.stringify(user)){
            if (user){
                const {id,title,status,country} = user;
                this.setState({id,title,status,country});
            }
        }
    }
    closeModal = () => {
        this.setState({
            id:'',
            title:'',
            status:'',
            country:''
        });
        this.props.onModalClose();
    };
    async submitHandler(ev) {
        if (ev.preventDefault) ev.preventDefault();
        else ev.returnValue = false;
    
        const {user} = this.props;
        const dataRes = await DistributorsService.update(this.state);
        if (!dataRes) {
          this.props.onShowMessage( DistributorsService.error,"error");
          return;
        }
        await this.props.onChanges();
    
        this.closeModal();
        this.props.onShowMessage(
          user
            ? `Distributor with id ${this.props.user.id} was updated.`
            : `User with id ${dataRes} was created`,
          "info"
        );
    }
    render() {
        const { onModalOpen, user } = this.props;
        const {
            id,
            title,
            status,
            country
        } = this.state;
        const user_info = user && {
            id:user.id,
            title:user.title,
            status:user.status,
            country:user.country,
        };
        const disableSubmit = !title || !status || !country || (user && 
            (JSON.stringify(user_info) === JSON.stringify({id,title,status,country})))
        return (
            <Modal
              open={onModalOpen}
              onClose={this.closeModal}
              onSubmit={this.submitHandler.bind(this)}
              formClassName="modal-distributor-form"
              header={ user ? "Edit distributor" : 'Add distributor' }
              buttons={
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      // onClick={() => this.submitHandler()}
                      type="submit"
                      disabled={disableSubmit}
                      variant="contained"
                      color="primary"
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      {user ? "Submit changes" : "Create distributor"}
                    </Button>
                  </>
              }
            >
                <Grid item xs={12} md={8} className="text-fields">
                    {user && (
                      <TextField
                        id="create-user-id"
                        label="User ID"
                        variant="outlined"
                        style={{ marginBottom: "6px" }}
                        disabled={true}
                        value={id}
                      />
                    )}
                    <TextField
                      label="Title"
                      variant="outlined"
                      style={{ marginBottom: "6px" }}
                      onChange={(event) =>
                        this.setState({ title: event.target.value })
                      }
                      value={title}
                    />
                    <TextField
                      label="Status"
                      variant="outlined"
                      style={{ marginBottom: "6px" }}
                      onChange={(event) =>
                        this.setState({ status: event.target.value })
                      }
                      value={status}
                    />
                    <Select
                      className="country-select"
                      value={country}
                      onChange={(event) =>
                        this.setState({ country: event.target.value })
                      }
                    >
                      {countries.map((country) => (
                        <MenuItem value={country.cca2}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                </Grid>
            </Modal>
        )      
    }    
}

const mapDispatchToProps = (dispatch) => {
    return {
      onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    };
  };
export default connect(null, mapDispatchToProps)(EditDistributorForm);