import Base from './baseClientService';

class ProgramService extends Base {
  // returns all available programs
  static async getPrograms() {
    try {
      return await this.GET(`/admin/programs`);
    } catch (e) {
      console.error('Error fetching programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  // adds program to the general list of programs
  static async addProgram(data) {
    try {
      const result = await this.POST(`/admin/programs/add`, data);
      return result;
    } catch (e) {
      console.error('Error adding programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  // updates program with given id
  static async updateProgram(program_id, data) {
    try {
      const result = await this.POST(
        `/admin/programs/update/${program_id}`,
        data
      );
      return result;
    } catch (err) {
      console.error('Error updating programs: ', err.message);
      this.Error(err.message);
      return false;
    }
  }

  // deletes program with given id
  static async deleteProgram(program_id) {
    try {
      const result = await this.POST(`/admin/programs/delete/${program_id}`);
      return result;
    } catch (err) {
      console.error('Error deleting program: ', err.message);
      return this.Error(err.message);      
    }
  }

  static async addTranslation( program_id, language_id ){
    console.debug( "addTranslation called with", program_id, language_id)
    try {
      const result = await this.POST(`admin/programs/translations/${program_id}/create/${language_id}`);
      return result;
    } catch (err) {
      console.error('Error adding translation: ', err.message);
      return this.Error(err.message);      
    }
  }
  static async deleteImage(program_id){
    console.debug( "deleteImage called with", program_id)
    try {
      const result = await this.POST(`admin/programs/${program_id}/image/delete`);
      return result;
    } catch (err) {
      console.error('Error deleting Image: ', err.message);
      return this.Error(err.message);      
    }
  }
  static async uploadImage(program_id, formData){
    console.debug( "uploadImage called with", program_id, formData)
    try {
      await this.POST(`/admin/programs/${program_id}/upload-image`, formData );
      return true;
    } catch (err) {
      console.error('Error uploading Image: ', err.message);
      return this.Error(err.message);      
    }
  }

  /**
   * Sets 'active' property of several programs at once
   * @param {Number} value 1|0
   * @param {Number[]} programIds 
   * @returns 
   */
  static async setActiveProperty(value, programIds){

    try {
      await this.POST(`/admin/programs/set-active`, {value, programIds} );
      return true;
    } catch (err) {
      console.error('Error setting active: ', err.message);
      return this.Error(err.message);      
    }
  }
}
Base.extend(ProgramService);

export default ProgramService;
