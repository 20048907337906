import React from 'react'
import CourseTypesTypeConfig from './course-types-type-config';
import { Button } from "@material-ui/core";
import CourseTypeConfigForm from './course-type-config-form';

class CourseTypesType extends React.Component {

  // state={
  //   course_type:null,
  // }
  render() {
    const { currentType } = this.props;
    // const {course_type} = this.state;
    return (<>
      {/* {JSON.stringify(currentType)}
        <CourseTypesTypeConfig
            config = {currentType.config}         
        /> */}
      {/* <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={() => this.setState({course_type:currentType})}
      >
        Edit
      </Button> */}
      {currentType && <CourseTypeConfigForm
      {...{course_type: currentType}}
      onShowMessage={this.props.onShowMessage} 
      onUpdate = {this.props.onUpdate}
      />}
    </>)
  }
}

export default CourseTypesType