import { Cancel, Check } from '@material-ui/icons';
import React from 'react';


const BooleanDisplay = (props) => {
    return (
        props.value === 0 ? 
        <Cancel style={{color: "red"}} size="small" {...props}/> : 
        <Check style={{color: "green"}} size="small" {...props}/>
        )
}

export default BooleanDisplay;