import React from 'react'
import { Redirect } from 'react-router-dom';
// import AdminTable from '../../../../shared-components/admin-table';
import {AdminTable} from '@ses-education/courses-components';


class DistributorUsers extends React.Component{

    state={
        redirect: null,
    }
    render(){
        const {users} = this.props;
        const {id} = this.props;
        const {redirect} = this.state;
        const columns = [
            { field: "id", headerName: "ID", flex: 1},
            { field: "full_name", headerName: "Full Name", flex: 1, renderCell: (params) =>
                <div
                    onClick={()=>this.setState({redirect:`/distributors/${id}/users/${params.row.id}`})}
                >
                    {`${params.row.name} ${params.row.last_name}`}
                </div>
            },
            { field: "email", headerName: "Email", flex: 1 },
            { field: "citizen_id", headerName: "Citizen Id", flex: 1 },
            { field: "password", headerName: "Password", flex: 1 },
            { field: "active", headerName: "Active", flex: 1 },
            { field: "fb_id", headerName: "Facebook Id", flex: 1 },
            { field: "google_id", headerName: "Google Id", flex: 1 },
            { field: "interface_language", headerName: "Interface Language", flex: 1 },
            { field: "status", headerName: "Status", flex: 1 },
            { field: "image", headerName: "Image", flex: 1 },
        ];
        if (redirect){
            return <Redirect to={redirect} />;
        }
        return <>
        {users && (
            <AdminTable
                autoHeight
                pageSize={10}
                columns={columns}
                rows={users}
            />
        )}
    </>
    }
}

export default DistributorUsers