import { AddBox, Delete } from '@material-ui/icons';
import React from 'react'
import { Redirect } from 'react-router-dom';
// import AdminTable from '../../../../shared-components/admin-table';
import AdminTable from '@ses-education/courses-components';
import {
    Button,
} from "@material-ui/core";

class Clients extends React.Component{

    state={
        redirect: null,
        selectedRows: null,
        confirmDialog: false,
        modalOpen: false,
    }
    render(){
        const {clients} = this.props;
        const {id} = this.props;
        const {redirect,selectedRows} = this.state;
        const columns = [
            { field: "id", headerName: "ID", flex: 1},
            { field: "client_title", headerName: "Title", flex: 1, renderCell: (params) =>
                <div
                    onClick={()=>this.setState({redirect:`/distributors/${id}/clients/${params.row.id}`})}
                >
                    {params.value}
                </div>
            },
            { field: "items", headerName: "Items", flex: 1 },
        ];
        const buttons = (
            <>
                <Button
                  onClick={() =>
                    this.setState({
                        modalOpen: true,
                    })
                  }
                  className="green-btn"
                  variant="contained"
                  startIcon={<AddBox />}
                >
                    Add
                </Button>
                <Button
                    onClick={() =>
                        this.setState({
                            confirmDialog: true,
                        })
                    }
                    color="secondary"
                    variant="contained"
                    disabled={!(Array.isArray(selectedRows) && selectedRows.length !== 0)}
                    startIcon={<Delete />}
                >
                    Delete
                </Button>
            </>
        );
        if (redirect){
            return <Redirect to={redirect} />;
        }
        return <>
        {clients && (
            <AdminTable
                autoHeight
                pageSize={10}
                columns={columns}
                rows={clients}
                buttons={buttons}
                checkboxSelection
                onSelectionModelChange={(selectedRows) => this.setState({selectedRows})}
                disableSelectionOnClick
            />
        )}
    </>
    }
}

export default Clients