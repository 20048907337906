import React from 'react';
import { Container, Tabs, Tab, Typography, Button } from '@material-ui/core';
import { EditRounded as EditIcon } from '@material-ui/icons';
import Loader from '../../../../template/common/loader';
import Form from './form';
import Editor, {tinymce} from '../../../../template/common/tinymce-editor/index';

import {
  defaultCoursesConfig,
  questionEditor,
} from '../../../../../data/tinymce-configs';

import Service from '../../../../../services/experiments';
import { connect } from 'react-redux';
import { showMessage } from '@ses-education/courses-components';

import {Parser} from '@ses-education/content-parser';
import '@ses-education/content-parser/dist/main.css';

import { Link } from 'react-router-dom';
import ProgressService from '../../../../../services/progress';

const tabid = 'tab-';
const tabpanelid = 'tab-field-';

const tinymceKey = process.env.REACT_APP_TINYMCE;

/**
 * Default init object for editors.
 */
const defaultInit = defaultCoursesConfig;

/**
 * Map tab to html field
 * @param {*} experiment
 * @param {*} exp_tab
 * @returns {array} array of [ current_html_string, html_field_name ]
 */
const getCurrentHtml = (experiment, exp_tab) => {
  const tabHtmlMap = {
    objectives: 'html_objectives',
    discussion: 'html_discussion',
    equipment: 'html_equipment',
    prep_questions: 'html_prep',
    experiment: 'html_experiment',
    summary_questions: 'html_questions',
  };

  console.debug('getCurrentHtml experiment:', experiment);

  return [experiment[tabHtmlMap[exp_tab]], tabHtmlMap[exp_tab]];
};

/**
 * Tabs data
 */
const tab_data = [
  { value: 'properties', title: 'Properties' },
  { value: 'objectives', title: 'Objectives' },
  { value: 'equipment', title: 'Equipment' },
  { value: 'discussion', title: 'Discussion' },
  { value: 'prep_questions', title: 'Preparation questions' },
  { value: 'experiment', title: 'Experiment' },
  { value: 'summary_questions', title: 'Summary questions' },
];

/**
 * Returns tab index based on tab from router.
 * @param {*} tab
 */
const getTabIndex = (tab) => tab_data.findIndex((t) => t.value === tab);

/**
 * "Regular editor" - for tabs without questions
 * @param {*} props
 */
const RegularEditor = (props) => {
  const {
    html,
    html_field,
    savingHtml,
    editingHtml,
    tinymceKey,
    cancel = console.debug,
    updateHtml = () => {},
    stateHandler,
  } = props;

  return (
    <div>
      {editingHtml === 1 ? (
        <Editor
          apiKey={tinymceKey}
          style={{ minHeight: '500px' }}
          content={html}
          disabled={savingHtml}
          onSave={(h) => updateHtml(h, html_field)}
          onCancel={cancel}
          init={defaultInit}
        />
      ) : (
        <>
          <div>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => stateHandler(1)}
            >
              <EditIcon fontSize='small' />
              Edit
            </Button>
          </div>
          <div
            className='course-html-preview'
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          ></div>
        </>
      )}
    </div>
  );
};

/**
 * "Question-driven editor", for tabs with questions in text
 * @param {*} props
 */
const QuestionsDrivenEditor = (props) => {
  const {
    html,
    html_field,
    savingHtml,
    editingHtml,
    tinymceKey,
    cancel = console.debug,
    EditorQuestions,
    questions,
    updateHtml = () => {},
    stateHandler,
  } = props;

  console.debug("QuestionsDrivenEditor props\n", props)
  return (
    <div>
      {editingHtml === 1 ? (
        <EditorQuestions
          apiKey={tinymceKey}
          content={html}
          disabled={savingHtml}
          onSave={(html) => updateHtml(html, html_field)}
          onCancel={() => cancel()}
        />
      ) : (
        <>
          <div>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => stateHandler(1)}
            >
              <EditIcon fontSize='small' />
              Edit
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={() =>
                Service.extractAndUpdateQuestions(
                  props.experiment.course_experiment_id,
                  html_field,
                  html
                )
              }
            >
              <EditIcon fontSize='small' />
              Update Questions
            </Button>
          </div>
          <div className='course-html-preview'>
            <Parser 
            html={html} 
            allQuestions={questions} 
            onQuestionChange={props.onQuestionChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

/**
 * One tab to rule them all component.
 */
const TabContent = (props) => {
  const { experiment = {} } = props;

  let html, html_field;

  console.debug("TabContent props", props)

  // get exp_tab, that comes from the router.
  const { exp_tab } = experiment;

  switch (exp_tab) {
    // all these cases are served by a regular editor
    case 'objectives':
    case 'equipment':
    case 'discussion':
      // get html and html field within experiment.
      [html, html_field] = getCurrentHtml(experiment, exp_tab);
      return <RegularEditor {...{ ...props, html, html_field }} />;

    // these cases are covered by questions-driven editor
    case 'prep_questions':
    case 'experiment':
    case 'summary_questions':
      // get html and html field within experiment.
      [html, html_field] = getCurrentHtml(experiment, exp_tab);
      return <QuestionsDrivenEditor {...{ ...props, html, html_field }} />;

    case 'properties':
      return (
        <div>
          <Form {...experiment} />
        </div>
      );

    // ooh, something went seriously wrong (this shoud NOT happen because of the router)
    default:
      return <div>Something went seriously wrong: there is no such tab</div>;
  }
};

class ExperimentEditor extends React.Component {
  state = {
    experiment: null,
    tabsIndex: 0,
    editingHtml: false,
    savingHtml: false,
  };

  componentDidMount() {}

  setTab = (ev, tabsIndex) => {
    this.setState({ tabsIndex });
  };

  /**
   * Updates given field with given html value
   * @param {*} html
   * @param {*} field
   */
  updateHtml = async (html, field) => {
    try {
      this.setState({ savingHtml: true });
      console.debug('uptateHtml to field ', field);
      const { course_id, course_experiment_id } = this.props;

      const experiment = await Service.updateExperiment(course_id, {
        [field]: html,
        course_experiment_id,
      });
      console.debug('uptateHtml: updated ', field);

      const questions = await Service.extractAndUpdateQuestions(
        course_experiment_id,
        field,
        html
      );

      // close editor
      this.setState({ editingHtml: false, savingHtml: false });

      this.onExperimentUpdate(experiment);
    } catch (err) {
      this.setState({ savingHtml: false });
      this.props.onShowMessage(err + ' ' + Service.error, 'error');
    }
  };

  onExperimentUpdate = (experiment) => {
    console.debug('Experiment updated to:', experiment);

    // it's already updated so it's safe to update it in state.
    // this.setState({experiment})

    const { onUpdate } = this.props;

    if (experiment && onUpdate) {
      // calling parent onUpdate
      console.debug('calling parent onUpdate');
      onUpdate(experiment);
    }
  };

  cancel = () => {
    this.setState({ editingHtml: false });
  };

  onQuestionChange = async (answersObj) => {
    /* console.log(
      'onQuestionChange inside ExperimentCycle - answersObj: ',
      answersObj
    ); */

    // const { currentExperiment } = this.state;
    // const { course_id, chapter } = this.props;



    try {
      const result = await ProgressService.checkAnswer (                
        answersObj
      );
      
      // const {
      //   courseStatusChange,
      //   experimentStatusChanged,
      //   pageStatusChanged,
      // } = result;
      // this.props.flagsHandler(
      //   courseStatusChange,
      //   experimentStatusChanged,
      //   pageStatusChanged
      // );

      //console.log('RESULT inside onQuestionChange ExperimentCycle: ', result);
      
      // 
      
      //return answers to parser
      return result;
    } catch (e) {
      console.log('Error inside onQuestionChange', e);
    }
  };

  render() {
    // const { tabsIndex } = this.state;

    return (
      <Loader data={this.props}>
        {(props) => {
          const {
            course_experiment_id,
            course_id,
            title,
            questions = [],
            exp_tab,
            updated_at
          } = props;

          const { savingHtml, editingHtml } = this.state;

          // get tabs index based on exp_tab, so that when the page is opened first time
          // it shows the correct tab selected, and also updates when browsing through tabs.
          const tabsIndex = getTabIndex(exp_tab);

          /**
           * Pre-packed editor for questions
           * @param {*} props
           */
          const EditorQuestions = (props) => {
            let { init } = props;

            return (
              <Editor
                onCancel={this.closeEditor}
                init={{
                  // use question editor config
                  ...questionEditor,

                  // pass course/experiment ids to the editor
                  course_experiment_id,
                  course_id,

                  // overwrite with passed props init, if any
                  ...init,
                }}
                {...props}
              />
            );
          };

          return (
            <Container>
              <Typography variant='h3' gutterBottom>
                {title}
              </Typography>
              {course_experiment_id ? (
                <>
                  <Tabs
                    value={tabsIndex}
                    // onChange={this.setTab}
                    // centered
                    variant='scrollable'
                    scrollButtons='auto'
                  >
                    {tab_data.map((t, ind) => (
                      <Tab
                        label={t.title}
                        id={`${tabid}${ind}`}
                        aria-controls={`${tabpanelid}${ind}`}
                        component={Link}
                        to={`/courses/${course_id}/experiments/${course_experiment_id}/${t.value}`}
                      />
                    ))}
                  </Tabs>

                  {/* One Tab to Rule Them All! */}
                  <TabContent
                    {...{
                      experiment: props,
                      tinymceKey: tinymceKey,
                      savingHtml,
                      editingHtml,
                      updateHtml: this.updateHtml,
                      cancel: this.cancel,
                      init: defaultInit,
                      EditorQuestions: EditorQuestions,
                      stateHandler: (editingHtml) =>
                        this.setState({ editingHtml }),
                      questions: questions,
                      onQuestionChange: this.onQuestionChange.bind(this)
                    }}
                  />
                </>
              ) : (
                <Form {...props} />
              )}
            </Container>
          );
        }}
      </Loader>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(ExperimentEditor);
export {tinymce}
