import React from "react";
import ProgramService from "../../../services/programs";
import OrganizationService from "../../../services/organizations";
import CourseSevrice from "../../../services/course";
import {
  Chip,
  // LinearProgress,
  // Grid,
  ButtonGroup,
  Button,
  Typography,
  // Breadcrumbs,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import {AutoBreadcrumbs, ConfirmDialog } from "@ses-education/courses-components";

import {
  // DataGrid,
  // GridOverlay,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";
import { showMessage } from "@ses-education/courses-components";
import ModalProgramForm from "../../template/modalProgramForm";
import {AdminTable} from "@ses-education/courses-components";
import "./programs.scss";
import Error from "../../template/Error";
import { Check, Close, Home, MoreVert } from "@material-ui/icons";
import ProgramForm from "./program-form";
import BooleanDisplay from "../../template/common/boolean-display";

const courseValuesSeparator = " ; ";
const courseInnerSeparator = " : ";
class Programs extends React.Component {
  state = {
    page: 1,
    perpage: 20,
    programs: null,
    allPrograms: null,
    courses: null,
    rows: [],
    rowsToShow: [],
    selectedRows: [],
    loading: false,
    modalOpen: false,
    error: null,
    id: null,
    renderModal: false,
    confirmDialog: false,
    selectedId: null,
    confirmDialogProps: null,
    programToEdit: null,
    mainOptionsEl: null, // element to anchor menu next to PROGRAMS header,
    mainOptions: {
      show: "active" // all|active|inactive
    }
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const programsLoaded = await this.fetchPrograms();
    const coursesLoaded = await this.fetchCourses();
    if (programsLoaded && coursesLoaded) {
      this.setState({ error: null, loading: false });
    }
  }

  fetchPrograms = async () =>  {
    try {
      let programs = [];
      const allPrograms = await ProgramService.getPrograms();

      // fetch organization specific programs
      if (this.props.match.params && this.props.match.params.org_id) {
        programs = await OrganizationService.getOrganizationPrograms(
          this.props.match.params.org_id
        );
      } else {
        programs = allPrograms;
      }

      if (programs && allPrograms) {
        this.setState({ programs, allPrograms }, () => this.populateRows() );
        return true;
      } else {
        this.setState({ error: "Failed loading programs" });
      }
    } catch (err) {
      console.debug(err);
      this.props.onShowMessage(
        `Fetching programs from server failed.`,
        "error"
      );
      return false;
    }
  }

  async fetchCourses() {
    const { page, perpage } = this.state;
    try {
      const courses = await CourseSevrice.getCourseListPage(page, perpage);
      if (courses) {
        this.setState({ courses });
        return true;
      } else {
        this.setState({ courses: false });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(`Fetching courses from server failed.`, "error");
      return false;
    }
  }

  selectProgram = async ( program_id ) => {
    const {programs} = this.state;
    const {onShowMessage} = this.props;

    if( !Array.isArray(programs) ){
      onShowMessage("Programs not loaded, please reload the page", "error");
      return false;
    }

    const programToEdit = programs.find( p => p.program_id === program_id);
    if( !programToEdit ){
      onShowMessage("Program with id=" + program_id + " not found!", "error")
      return false;
    }

    this.setState({programToEdit}); 
  }

  /**
   * populates programs and stores "rows" array to state
   * @param {*} programs
   */
  // populateRows = (programs)  => {
  populateRows = ()  => {

    const {programs} = this.state;
    // let rows = [];
    const {mainOptions} = this.state;

    // both 0 and 1 'active' state will be shown
    let show = [0,1];

    switch (mainOptions.show){
      case 'active':
        show = [1]
        break;
      case 'inactive':
        show = [0]
        break;
      case 'all':
      default:
        // all
        break;
    }

    const rows = programs.map((p) => ({
      id: p.program_id,
      ...p,
    }))
    //
    .filter( p => show.includes( p.active ) );

    // programs.forEach((p) => {
    //   const item = {
    //     id: p.program_id,
    //     program_name: p.title,
    //     courses_list: this.populateCourses(p.courses),
    //   };
    //   rows.push(item);
    // });
    this.setState({ rows });
  }

  closeDialogHandler() {
    this.setState({ confirmDialog: false, selectedProgram: null });
  }

  async assignProgramHandler() {
    const { org_id } = this.props.match.params;
    const { selectedId } = this.state;
    const result = await OrganizationService.assignProgramToOrganization(
      org_id,
      selectedId
    );

    if (result) {
      await this.fetchPrograms();
      this.props.onShowMessage(
        `Program with ID #${selectedId} was assigned to organization with ID #${org_id}`,
        "info"
      );
    }
    this.closeDialogHandler();
  }

  async deleteProgramHandler(id) {
    const {onShowMessage} = this.props;
    const result = await ProgramService.deleteProgram(id);
    if (result) {
        await this.fetchPrograms();
        onShowMessage(
          `Program with ID #${id} was deleted successfuly`,
          "info"
        );
        this.setState({confirmDialogProps: null})
    } else {
      onShowMessage(ProgramService.error || "Unknown error", "error");
    }
  }

  updateProgramHandler = async (program) => {
    // return console.debug("updateProgramHandler", program);
    const {onShowMessage} = this.props;
    const { org_id } = this.props.match.params;

    const updated_program_id = org_id
        ? // adds new program and assigns to organization with given org_id
          await OrganizationService.updateOrganizationProgram(org_id, program)
        : // only adds new program to general list of programs or updates if program exists
        (
          program.program_id ?
          await ProgramService.updateProgram(program.program_id, program) :
          await ProgramService.addProgram(program)
        )
      
    if( !updated_program_id ){
      return onShowMessage(
        (org_id ? OrganizationService.error : ProgramService.error) || "Unknown error",
        "error"
      )
    }

    onShowMessage( program.program_id ? "Program successfully updated" : "Program successfully added", "success");

    // fetch the updated program list 
    await this.fetchPrograms();

    // now set the selected id to the updated program id
    this.selectProgram( updated_program_id);

  }

  /**
   * receives array of courses - converts into array of Chip components
   * @param {*} courses
   * @returns
   */
  populateCourses(courses) {
    const chipArr = [];
    if (courses[0] === null) return ["No courses assigned"];
    courses.forEach((course) => {
      chipArr.push(
        <Chip
          label={course.title}
          color="primary"
          variant="outlined"
          style={{ margin: "4px" }}
          size="small"
          key={course.coure_code}
        />
      );
    });
    return chipArr;
  }

  async unassignAndDeleteProgramHandler(program_id) {
    const { org_id,onShowMessage } = this.props;
    const result = await OrganizationService.unassignAndDeleteProgram(
      org_id,
      program_id
    );
    console.log("unassignAndDeleteProgramHandler - result: ", result);
    if (result) {
      await this.fetchPrograms();
      onShowMessage(
        `Program with ID #${program_id} was permanently deleted`,
        "info"
      );
      // close confirm dialog
      this.setState({confirmDialogProps: null})
    } else {
      onShowMessage( OrganizationService.error || "Unknown error", "error");
    }
  }

  // loadingOverlay() {
  //   return (
  //     <GridOverlay>
  //       <div style={{ position: "absolute", top: 0, width: "100%" }}>
  //         <LinearProgress />
  //       </div>
  //     </GridOverlay>
  //   );
  // }

  async removeFromOrganization(program_id) {
    const { org_id,onShowMessage } = this.props.match.params;
    const result = await OrganizationService.removeProgramFromOrganization(
      org_id,
      program_id
    );
    if (result) {
      await this.fetchPrograms();
      onShowMessage(
        `Program with ID #${program_id} was removed from the organization`,
        "info"
      );
      // close confirm dialog
      this.setState({confirmDialogProps: null})
    }else {
      onShowMessage( OrganizationService.error || "Unknown error", "error");
    }
  }

  onDeleteImage = async () => {
    const { onShowMessage } = this.props;
    
    const {programToEdit} = this.state;
    console.debug("onDeleteImage called with", programToEdit);


    if (!programToEdit?.program_id) {
      onShowMessage("No program is selected", "error");
      return false;
    }

    if (!(await ProgramService.deleteImage(programToEdit.program_id))) {
      onShowMessage(ProgramService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Image successfully deleted");
    await this.fetchPrograms();
    return true;
  }

  onSelectImage = async ({ target }) => {
    console.debug("onSelectImage called", target);
    const { onShowMessage } = this.props;

    const {programToEdit} = this.state;

    if (!programToEdit?.program_id) {
      onShowMessage("Program is not saved yet", "error");
      return false;
    }

    const image = target.files && target.files[0] ? target.files[0] : null;

    if (!image) {
      onShowMessage("No file selected", "error");
      return false;
    }

    // build form data with file
    const data = new FormData();
    data.append("image", image);

    // console.debug("uploading data", data);
    if (!(await ProgramService.uploadImage(programToEdit.program_id, data))) {
      onShowMessage(ProgramService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Image successfully updated");
    await this.fetchPrograms();
    return true;
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      id: null,
      renderModal: false,
    });
  }


  onSelectRows = (selectedRows) => {
    // console.debug(model);
    this.setState({selectedRows});
  }

  /**
   * sets the value for active/inactive display
   * @param {Event} ev click event 
   */
  onSelectShow = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    const {mainOptions} = this.state;
    mainOptions.show = ev.target.value;
    this.setState({mainOptions}, () => {
      // when state has changed - refilter rows with new value
      this.populateRows();
    });
  }


  /**
   * Assigns 'active' property of selected programs
   * @param {Number} value 1|0 - value for 'active' property of selected programs
   */
  onActivationChange = async (value) => {
    const {onShowMessage} = this.props;

    // pass to service, show error if fails
    if( ! await ProgramService.setActiveProperty( value, this.state.selectedRows ) ){
      return onShowMessage(ProgramService.error || "Unknown error", "error");  
    }

    // re-fetch
    this.fetchPrograms();
  }

  render() {
    const {
      loading,
      rows,

      selectedRows,
      // modalOpen,
      error,
      courses,
      // id,
      confirmDialog,
      programs,
      selectedId,
      allPrograms,
      confirmDialogProps,
      programToEdit,
      mainOptions,
      mainOptionsEl
    } = this.state;
    const { org_id } = this.props.match.params;

    const {onShowMessage} = this.props;

    const columns = [
      { field: "id", headerName: "ID", flex: 0.8 },
      // { field: "program_name", headerName: "Name", flex: 2 },
      { field: "title", headerName: "Name", flex: 2 },
      {
        field: "courses",
        headerName: "Courses",
        flex: 8,
        description: "List of courses present in the current program",
        // join all course codes with comma
        valueGetter: ({ value }) =>
          Array.isArray(value)
            ? value
                .filter((e) => e)
                .map((v) =>
                  v
                    ? `${v.course_code}${courseInnerSeparator}${v.title}`
                    : JSON.stringify(v)
                )
                .join(courseValuesSeparator)
            : JSON.stringify(value),
        valueFormatter: ({ value }) =>
          Array.isArray(value)
            ? value.map((v) => v.course_code).join(", ")
            : JSON.stringify(value),
        renderCell: ({ value }) => (
          // <div className="course-list">{params.value}</div>
          <div className="course-list">
            {typeof value === "string" && value.length > 0 ? (
              // have to do this "join then split back" thing
              // because of valueFormatter not working properly
              // and the only way to get foratted values to CSV is to return it via valueGetter
              // So we return a readable string in valueGetter, then split it and display as chips here...
              value.split(courseValuesSeparator).map((c) => {
                const [code, title] = c.split(courseInnerSeparator);
                return (
                  <span className="chip" data-title={title}>
                    {code}
                  </span>
                );
              })
            ) : (
              <span className="no-courses">no courses assigned</span>
            )}
          </div>
        ),
      },
      { field: "active", headerName: "Active", flex: 0.2, renderCell: (params) => {
        // display as check/close icon
        return <BooleanDisplay {...params} />
      } },
      {
        field: "buttons",
        headerName: "Actions",
        flex: 4,
        disableExport: true,
        valueGetter: ({ value }) => "-",
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                onClick={() =>
                  this.selectProgram(params.id)
                }
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              {/* <Button
                onClick={() =>
                  this.setState({
                    modalOpen: true,
                    id: params.id,
                    renderModal: true,
                  })
                }
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button> */}
              <Button
                // onClick={
                //   org_id
                //     ? () => this.removeFromOrganization(params.id)
                //     : () => this.deleteProgramHandler(params.id)
                // }

                onClick={() => {
                  this.setState({
                    confirmDialogProps: {
                      header: org_id ? "Removing program from organization" : "Deleting program",
                      prompt: org_id 
                        ? "Are you sure you want to remove the program #" + params.id + " from organization?" 
                        : "Are you sure you want to delete the program #" + params.id + "?",

                      onConfirm: org_id 
                        ? () => this.removeFromOrganization(params.id) 
                        : () => this.deleteProgramHandler(params.id),

                      onClose: () => this.setState({confirmDialogProps: null})
                    }
                  })

                  
                }
              }
                variant="contained"
                style={{ backgroundColor: "#e7584e", color: "white" }}
                size="small"
                startIcon={<DeleteIcon />}
              >
                {org_id ? "Unassign" : "Delete"}
              </Button>
              {org_id && (
                <Button
                  onClick={() => {
                      this.setState({
                        confirmDialogProps: {
                          header: "Deleting program",
                          prompt: "Are you sure you want to delete the program #" + params.id + "?",
                          onConfirm: this.unassignAndDeleteProgramHandler(params.id),
                          onClose: () => this.setState({confirmDialogProps: null})
                        }
                      })

                      
                    }
                  }
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<DeleteForeverIcon />}
                >
                  Delete
                </Button>
              )}
            </ButtonGroup>
          </div>
        ),
      },
    ];

    const buttons = (
      <>
        {org_id && (
          <Button
            onClick={() =>
              this.setState({
                confirmDialog: true,
              })
            }
            color="secondary"
            variant="contained"
            startIcon={<AddBoxIcon />}
          >
            Assign existing program
          </Button>
        )}
        <Button
          onClick={() =>
            this.setState({
              programToEdit: {}
            })
          }
          // onClick={() =>
          //   this.setState({
          //     modalOpen: true,
          //     id: null,
          //     org_id: org_id,
          //     renderModal: true,
          //   })
          // }
          className="green-btn"
          variant="contained"
          size="small"
          startIcon={<AddBoxIcon />}
        >
          Create new
        </Button>
        {
          selectedRows && selectedRows.length > 0 && (
            <>
            <Button
              onClick={(ev) =>{ this.onActivationChange(1) }}
              className="bg-orange"
              variant="contained"
              startIcon={<Check />}
              size="small"
            >
              Activate
            </Button>
            <Button
              onClick={(ev) =>{ this.onActivationChange(0) }}
              className="bg-orange"
              variant="contained"
              startIcon={<Close />}
              size="small"
            >
              Deactivate
            </Button>
            </>
          )
        }

        <GridToolbarExport csvOptions={{ fields: ["id", "title"] }} />
      </>
    );

    if (error) return <Error {...{ error }} />;

    // prepare programs in list and not in
    const programsInListIds = Array.isArray(rows) ? rows.map((r) => r.program_id) : [];
    const programsNotInList = allPrograms
      ? allPrograms.filter((p) => !programsInListIds.includes(p.program_id))
      : [];

    return (
      <div className="page programs">
        {this.props.match.params && org_id ? (
          <>
            <AutoBreadcrumbs
              items={[
                { to: "/", icon: <Home /> },
                { to: "/organizations", text: "Organizations" },
                { to: `/organizations/${org_id}`, text: org_id },
                { text: "Programs" },
              ]}
            />
            <Typography variant="h1" color="primary">
              Organization #{org_id}: Programs
            </Typography>
          </>
        ) : (
          <Typography variant="h1" color="primary" className="flex justify-content-space-between">
            Programs
            <Box>
              <span style={{color: "gray", fontSize: "0.6em"}}>Showing: {mainOptions.show} programs</span>
              <IconButton onClick={({target: mainOptionsEl}) => this.setState({mainOptionsEl})}>
                <MoreVert/>
                <Menu 
                  anchorEl={mainOptionsEl} 
                  onClick={(ev) => ev.stopPropagation() }
                  open={Boolean(mainOptionsEl)} 
                  onClose={()=> this.setState({mainOptionsEl: null})}
                >
                  
                    <FormControl component="fieldset"  className="m-x-6">
                      <FormLabel component="legend">Show programs</FormLabel>
                      <RadioGroup onClick={(ev) => ev.stopPropagation() } size="small" aria-label="show-select" name="show" value={mainOptions.show} onChange={this.onSelectShow}>
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                        <FormControlLabel value="active" control={<Radio />} label="Active" />
                        <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                      </RadioGroup>
                    </FormControl>
                  
                </Menu>
              </IconButton>
            </Box>
            
          </Typography>
        )}
        <AdminTable
          columns={columns}
          rows={rows}
          buttons={buttons}
          loading={loading}
          autoHeight
          searchFields={["id", "title", "courses"]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={this.onSelectRows}
          
        />
        {/* <Grid container>
          {this.props.match.params && org_id ? (
            <Grid container style={{ margin: "0 0 10px 20px", padding: "5px" }}>
              <Breadcrumbs>
                <NavLink to="/organizations">Organizations</NavLink>
                {org_id && (
                  <NavLink to={`/organizations/${org_id}`}>{org_id}</NavLink>
                )}

                {<span>Programs</span>}
              </Breadcrumbs>
            </Grid>
          ) : null}
          <Grid
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              onClick={() =>
                this.setState({
                  modalOpen: true,
                  id: null,
                  org_id: org_id,
                  renderModal: true,
                })
              }
              className="green-btn"
              variant="contained"
              startIcon={<AddBoxIcon />}
            >
              Create new program
            </Button>
            {org_id && (
              <Button
                onClick={() =>
                  this.setState({
                    confirmDialog: true,
                  })
                }
                className="green-btn"
                variant="contained"
                startIcon={<AddBoxIcon />}
              >
                Assign existing program
              </Button>
            )}
          </Grid>
          <Grid
            item
            style={{
              height: "70vh",
              width: "100%",
            }}
          >
            <DataGrid
              components={{
                LoadingOverlay: this.loadingOverlay,
              }}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowHeight={80}
              disableSelectionOnClick
              loading={loading}
            />
          </Grid>
        </Grid> */}

        {programs ? (
          <Dialog
            open={confirmDialog}
            onClose={() => this.closeDialogHandler()}
            className="programs-assign-dialog"
          >
            <DialogTitle>
              {`Choose program to assign to organization ${org_id}`}
            </DialogTitle>
            <DialogContent>
              <List>
                {/* {allPrograms.map((program) => { */}
                {programsNotInList.map((program) => {
                  const labelId = `checkbox-list-label-${program}`;

                  return (
                    <ListItem
                      key={program.program_id}
                      dense
                      button
                      onClick={() =>
                        this.setState({ selectedId: program.program_id })
                      }
                      selected={program.program_id === selectedId}
                    >
                      <ListItemText
                        id={labelId}
                        primary={`${program.program_id} - Program ${program.title}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.closeDialogHandler()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.assignProgramHandler()}
              >
                Assign
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        { programToEdit && <ProgramForm 
            program={programToEdit} 
            open={true} 
            allCourses={courses}
            onClose={()=> this.setState({programToEdit: null})} 
            onSave={this.updateProgramHandler}
            
            onSelectImage={this.onSelectImage}
            onDeleteImage={this.onDeleteImage}
            
            showMessage={onShowMessage}

        /> }

        {/* {this.state.renderModal ? (
          <ModalProgramForm
            onModalOpen={modalOpen}
            id={id}
            mode={"edit"}
            org_id={org_id}
            courses={courses}
            onModalClose={this.closeModal.bind(this)}
            onChanges={async ( addId ) => {
              console.debug("updated/added program:", addId);
              await this.fetchPrograms();
              console.debug("Programs fetched");

              if( addId ){
                console.debug("setting current program to", addId)
                // a new program is added - make it current
                this.setState({id: addId})
              }

              }
            }
          />
        ) : null} */}
        {
          confirmDialogProps && <ConfirmDialog open={true} {...confirmDialogProps} />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(Programs);
