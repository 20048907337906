import React from 'react'
import { Button, TextField, Typography, List, ListItem, Select, MenuItem } from "@material-ui/core";
import store from "../../../../redux/store";
// import { showMessage } from "../../../../shared-components/notifications/notificationActions";
import { showMessage } from "@ses-education/courses-components";
import content_templates from '../../../../data/content-templates.json';
import AsyncButton from "../../../template/AsyncButton";
import SelectCategoriesForm from './select-categories-form';
import './course-type.scss';
import CourseService from '../../../../services/course';
import { Redirect } from 'react-router-dom';

class CourseTypeConfigForm extends React.Component {

  state = {
    course_type: null,
    original_course_type: null,
    edit_category_form: false,
    allCategories: null,
    redirect: null,
  }
  componentDidMount() {
    this.fetchCategories();
  }

  componentDidUpdate(prevProps) {
    if (this.props.course_type !== prevProps.course_type) {
      this.populateState();
    }
  }

  fetchCategories = async () => {
    const allCategories = await CourseService.getCourseTypesCategories();
    // console.debug("test2 fetched categories:", allCategories);
    if (!Array.isArray(allCategories)) {
      this.props.onShowMessage("Error loading course types categories");
      return;
    }
    this.setState({
      allCategories: [...allCategories],
    }, this.populateState);
  }

  populateState = () => {
    let { course_type } = this.props;
    const { allCategories } = this.state;
    let {
      course_template = "default-course",
      default_category_template = "default-category",
      default_page_template = "default-page",
      default_content_item_template = "default-content-item",
    } = course_type?.config || {};

    // get the object from string id
    course_template = content_templates.find(template => template.template_id == course_template)
    default_category_template = content_templates.find(template => template.template_id == default_category_template)
    default_page_template = content_templates.find(template => template.template_id == default_page_template)
    default_content_item_template = content_templates.find(template => template.template_id == default_content_item_template)
    const categories = (course_type?.categories || []).map(cat => {
      const { content_cat_id, ordering } = cat;
      return {
        ...allCategories.find(cat => cat.content_cat_id == content_cat_id),
        ordering,
      };
    });
    // console.debug("test:", categories, allCategories, course_type);
    course_type = {
      ...course_type,
      config: {
        course_template,
        default_category_template,
        default_page_template,
        default_content_item_template
      },
      categories: [...categories],
    }
    this.setState({
      course_type, original_course_type: {
        ...course_type,
        config: {
          ...course_type.config,
        },
        categories: [...categories],
      }
    });
  }

  updateField = ({ target }) => {
    try {
      const { name, value } = target;

      let { course_type } = this.state;
      if (typeof course_type !== "object") throw new Error("No type to edit");

      // update field within editCourseType
      course_type[name] = value;
      this.setState({ course_type });
    } catch (e) {
      console.error("updateField error:", e);
      store.dispatch(
        showMessage(
          "Error updating value" + target.name || "[unknown field]",
          "error"
        )
      );
    }
  }

  handleSelectChange = ({ target }) => {
    try {
      const { name, value } = target;

      let { course_type } = this.state;
      if (typeof course_type !== "object") throw new Error("No type to edit");

      // update field within editCourseType
      if (course_type?.config) {
        course_type.config[name] = { ...content_templates.find(c => c.template_id == value) };
        this.setState({ course_type });
      }
    } catch (e) {
      console.error("updateField error:", e);
      store.dispatch(
        showMessage(
          "Error updating value config." + target.name || "[unknown field]",
          "error"
        )
      );
    }
  }

  saveChanges = async () => {
    const { course_type } = this.state;
    // console.log('test2',course_type)
    course_type.course_type_id ? await this.updateType() : await this.createType();
    this.setState({ redirect: '/settings/course-types' });
  }

  createType = async () => {
    let { course_type } = this.state;
    const { onUpdate } = this.props;
    course_type = {
      ...course_type,
      config: [
        ...Object.values(course_type.config),
      ],
    }
    const result = await CourseService.addCourseType(course_type);
    // if didnt get result show error
    if (!result) {
      this.props.onShowMessage(
        "Error adding course type: " + CourseService.error,
        "error"
      );
      return;
    }
    // if got result do onUpdate
    await onUpdate();
  }

  updateType = async () => {
    let { course_type } = this.state;
    const { onUpdate } = this.props;
    course_type = {
      ...course_type,
      config: [
        ...Object.values(course_type.config),
      ],
    }
    const result = await CourseService.updateCourseType(course_type.course_type_id,course_type);
    // if didnt get result show error
    if (!result) {
      this.props.onShowMessage(
        "Error updating course type: " + CourseService.error,
        "error"
      );
      return;
    }
    // if got result do onUpdate
    await onUpdate();
  }

  render() {

    const { course_type, edit_category_form, original_course_type, allCategories, redirect } = this.state;
    //console.log("test", course_type)
    const {
      // categories = [
      //   {
      //     content_cat_id: 14,
      //     title: 'Base Category 1',
      //   },
      //   {
      //     content_cat_id: 23,
      //     title: 'Base Category 2',
      //   },
      //   {
      //     content_cat_id: 2,
      //     title: 'Base Category 3',
      //   },
      // ]
      categories,
    } = course_type || {};

    if (redirect) {
      return <Redirect to={redirect} />
    }
    const disableSave = JSON.stringify(course_type) === JSON.stringify(original_course_type);

    return (
      <>
        {/* </>
      <ModalWindow
        open={Boolean(course_type)}
        onClose={onClose}
        onSubmit={() => onSave(course_type)} // catch all types of form submit
        header="Course type"
        {...other}
      > */}
        <Typography>Course type</Typography>
        <TextField
          id="outlined-title"
          label="Type title"
          variant="outlined"
          fullWidth
          name="title"
          onChange={this.updateField}
          value={course_type?.title}
          placeholder="Type in new type title here"
          className="m-top-5 m-bottom-5"
        />
        <TextField
          id="outlined-description"
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          name="description"
          onChange={this.updateField}
          value={course_type?.description}
          placeholder="Type in description here"
          className="m-top-5 m-bottom-5"
        />
        <Typography>Config</Typography>
        <div className='config'>
          <div className='template-list'>
            {course_type?.config && Object.entries(course_type?.config).map(([key, value]) => (
              <>
                <label for={`${key}-select`}>{key}</label>
                <Select
                  id={`${key}-select`}
                  value={value.template_id}
                  name={key}
                  onChange={this.handleSelectChange}
                >
                  {Array.isArray(content_templates) && content_templates.map(template =>
                    <MenuItem value={template.template_id}>{template.title}</MenuItem>
                  )}
                </Select>
              </>
            ))}
          </div>
          <div>
            <div>
              <Typography>Base Categories</Typography>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => this.setState({ edit_category_form: true })}
              >
                Edit
              </Button>
            </div>
            <List variant="persistent" className="category-list">
              {Array.isArray(categories) && categories.map(cat => (
                <ListItem>
                  {`${cat.content_cat_id} : ${cat.title}`}
                </ListItem>
              ))}
            </List>
          </div>
        </div>
        <AsyncButton
          variant="contained"
          color="primary"
          type="button"
          disabled={disableSave}
          onClick={this.saveChanges}
        >
          Save
        </AsyncButton>
        {edit_category_form &&
          <SelectCategoriesForm
            {...{ categories, allCategories }}
            onClose={() => this.setState({ edit_category_form: false })}
            onSave={(categories) => this.setState({
              course_type: {
                ...course_type,
                categories
              },
              edit_category_form: false,
            })}
            onShowMessage={this.props.onShowMessage}
            onUpdate={this.fetchCategories}
          />
        }
        {/* </ModalWindow> */}
      </>
    );
  }
}

export default CourseTypeConfigForm