import React from 'react'


class Client extends React.Component{

    render(){
        const { client_id } = this.props.match.params;
        return(`${client_id}`)
    }
}

export default Client