import { Button, List, ListItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import store from "../../../../redux/store";
import CourseService from "../../../../services/course";
// import ModalWindow from "../../../../shared-components/modal-window";
import ModalWindow from "@ses-education/courses-components";
// import { showMessage } from "../../../../shared-components/notifications/notificationActions";
import { showMessage } from "@ses-education/courses-components";
import ContentCategoryForm from "../../courses/at5000/content-category-form";

const emptyCategory = {
  title: "",
};
class SelectCategoriesForm extends React.Component {

  state = {
    categories: [],
    allCategories: [],
    allCategoriesSearch: "",
    categoriesSearch: "",
    addCategory: null,
  };

  componentDidMount() {
    const { categories, allCategories } = this.props;
    this.setState({ categories, allCategories });
  }

  componentDidUpdate(prevProps) {
    if (this.props.categories !== prevProps.categories)
      this.setState({ categories: this.props.categories });
    if (this.props.allCategories !== prevProps.allCategories)
      this.setState({ allCategories: this.props.allCategories });
  }

  updateField = ({ target }) => {
    try {
      const { name, value } = target;

      // update search field
      this.setState({ [name]: value });
    } catch (e) {
      console.error("updateField error:", e);
      store.dispatch(
        showMessage(
          "Error updating value" + target.name || "[unknown field]",
          "error"
        )
      );
    }
  }
  selectCategory = ({ target }) => {
    let { allCategories, categories } = this.state;

    const category = {
      ...allCategories.find(cat => cat.content_cat_id == parseInt(target.id)),
      ordering: Array.isArray(categories) && categories.length > 0 ? Math.max(...categories.map((c) => c.ordering)) + 1
      :
        1,
    }
    allCategories = allCategories.filter(cat => cat.content_cat_id !== parseInt(target.id))
    this.setState({
      allCategories: [...allCategories],
      categories: [...categories, category]
    })
  }
  removeCategory = ({ target }) => {
    let { allCategories, categories } = this.state;
    const category = {
      ...categories.find(cat => cat.content_cat_id == parseInt(target.id)),
      ordering: null,
    }
    categories = categories.filter(cat => cat.content_cat_id !== parseInt(target.id))
    this.setState({
      categories: [...categories],
      allCategories: [...allCategories, category]
    })
  }

  saveCategory = async (category) => {
    const { onUpdate } = this.props;
    if (!category || typeof category !== "object") {
      console.debug("saveCategory: expected object, got:", category);
      return this.props.onShowMessage("Error in category data");
    }
    // add new category
    const newcategory = await CourseService.addTypeCategory({...category});
    // if didnt get result show error
    if (!newcategory) {
      this.props.onShowMessage(
        "Error adding category: " + CourseService.error,
        "error"
      );
      return;
    }
    // console.log('test2', newcategory)
    // update categories
    await onUpdate();
  }

  render() {
    const {
      onClose = console.debug,
      onSave = console.debug,
    } = this.props;
    const { categories, allCategories, allCategoriesSearch, categoriesSearch, addCategory } = this.state;
    //console.log("test3", this.state)
    let disableUpdate = true;
    if (Array.isArray(categories) && Array.isArray(this.props.categories)) {
      categories.map(cat => disableUpdate = disableUpdate && Boolean(
        this.props.categories.find(cat2 => cat2.content_cat_id == cat.content_cat_id)
      ))
      disableUpdate = disableUpdate && this.props.categories.length == categories.length
    }
    return (
      <ModalWindow
        open={Boolean(categories)}
        header={"Select linked base categories"}
      >
        <div className="all-categories">
          <div className="justify-content-space-between">
            <Typography>All categories</Typography>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => this.setState({
                addCategory: {
                  ...emptyCategory,
                }
              })}
            >
              +
            </Button>
          </div>
          <div className="search-bar">
            <TextField
              id="outlined-title"
              label="Search"
              variant="outlined"
              fullWidth
              name="allCategoriesSearch"
              onChange={this.updateField}
              value={allCategoriesSearch}
              placeholder="Search..."
              className="m-top-5 m-bottom-5"
            />
          </div>
          <List variant="persistent" className="all-categories-list">
            {Array.isArray(allCategories) && allCategories.filter(cat =>
              cat.title.toLowerCase().includes(allCategoriesSearch.toLowerCase())).map((cat) => {
                return <ListItem onClick={this.selectCategory} id={cat.content_cat_id} >{cat.content_cat_id} : {cat.title}</ListItem>
              })}
          </List>
        </div>
        <div className="selected-categories">
          <Typography>Selected categories</Typography>
          <div className="search-bar">
            <TextField
              id="outlined-title"
              label="Search"
              variant="outlined"
              fullWidth
              name="categoriesSearch"
              onChange={this.updateField}
              value={categoriesSearch}
              placeholder="Search..."
              className="m-top-5 m-bottom-5"
            />
          </div>
          <List variant="persistent" className="selected-categories-list">
            {Array.isArray(categories) && categories.filter(cat =>
              cat.title.toLowerCase().includes(categoriesSearch.toLowerCase()))
              .map((cat) => {
                return <ListItem onClick={this.removeCategory} id={cat.content_cat_id} >{cat.content_cat_id} : {cat.title}</ListItem>
              })}
          </List>
        </div>
        <div className="flex align-self-stretch align-items-center justify-content-center">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => onSave([...categories])}
            disabled={disableUpdate}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            type="button"
          >
            Cancel
          </Button>
        </div>
        {addCategory && (
          <ContentCategoryForm
            category={addCategory}
            onClose={() => this.setState({ addCategory: null })}
            onSave={() => {
              // save...
              this.saveCategory(addCategory);
              // ...and close form
              this.setState({ addCategory: null });
            }}
          />
        )}
      </ModalWindow>
    )
  }
}

export default SelectCategoriesForm