import Base from './baseClientService';
// import {strFormat} from '../utils/functions'

const allExperimentsIndex = 'allexp';
const oneExpIndex = 'course_%id%';

const pageNames = {
  html_prep: 'prep',
  html_experiment: 'experiment',
  html_summary: 'summary',
  html_questions: 'summary', //duplicate
};

class ExperimentService extends Base {

  static async getExperiment( experiment_id ){
    try {
      return await this.GET(
        `/admin/experiments/${experiment_id}`
      );
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }


  static async addExperiment(course_id, data) {
    try {
      const result = await this.POST(
        `/admin/courses/${course_id}/add-experiment`,
        data
      );
      return result;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async addTranslation(experiment_id, language_id) {    
    return await this.POST(`/admin/experiments/${experiment_id}/add-translation/${language_id}` );          
  }

  /**
   * returns {id: null} for each question in prep, experiment and summary
   * @param {*} exp_id
   * @param {*} field
   * @param {*} html
   */
  static async extractAndUpdateQuestions(exp_id, field, html) {
    console.debug("extractAndUpdateQuestions", exp_id, field, "html:\n", html);
    let extractedQestions = [];
    const container = document.createElement('div');
    container.innerHTML = html;
    const questions = container.querySelectorAll('[data-question-id]');
    // console.debug("questions:", questions );
    
    // questions is a NodeList, can't use map
    questions.forEach(
      (q) => (extractedQestions = [...extractedQestions, q.dataset.questionId])
    );

    // console.debug("extracted questions:", extractedQestions );
    // return false;

    //only send server call if prep, experiment or summary
    if (field in pageNames) {
      try {
        const result = await this.POST(
          `/admin/courses/upd-page/${exp_id}/${pageNames[field]}`,
          { questions: extractedQestions }
        );
        return result;
      } catch (e) {
        this.Error('Error inside extractAndUpdateQuestions: ', e.message);
        return false;
      }
    }
  }

  static async updateExperiment(course_id, data) {
    try {
      const result = await this.POST(
        `/admin/courses/${course_id}/upd-experiment`,
        data
      );
      return result;
    } catch (e) {
      this.Error('Error updating experiment: ', e.message);
      return false;
    }
  }

  /**
   * Updates experiment record without mentioning the course. Used to update experiment translations, for example.
   * @param {*} data 
   * @returns 
   */
  static async updateExperimentDirect(data) {    
    return await this.POST(
        `/admin/experiments/update`,
        data
      );        
  }

  static async uploadImage(file) {
    try {
      const result = await this.POST('/admin/courses/upload-image', file);
      return result;
    } catch (e) {
      this.Error('Error uploading image: ', e.message);
      return false;
    }
  }

  static async uploadVideo(file) {
    try {
      const result = await this.POST('/admin/courses/upload-video', file);
      return result;
    } catch (e) {
      this.Error('Error uploading video: ', e.message);
      return false;
    }
  }

  static async deleteExperiment(exp_id) {
    try {
      const result = await this.POST(
        `/admin/courses/delete-experiment/${exp_id}`
      );
      return result;
    } catch (err) {
      this.Error('Error deleting experiment: ' + err.message);
      return false;
    }
  }

  static async updateQuestion(course_id, exp_id, questionData) {
    try {
      const result = await this.POST(
        `/admin/courses/${course_id}/exp/${exp_id}/upd-question`,
        questionData
      );
      return result;
    } catch (err) {
      return this.Error(err.message || "Undefined error while updating the question")
    }
  }

  static async getQuestion(course_id, exp_id, question_id) {
    try {
      const result = await this.GET(
        `/admin/courses/${course_id}/exp/${exp_id}/get-question/${question_id}`
      );
      return result;
    } catch (err) {
      console.log('Error getting question: ' + err.message);
      return false;
    }
  }

  static async getPageQuestions( experiment_id, page_slug) {
    return await this.GET(
      `/admin/experiments/${experiment_id}/page/${page_slug}/questions`
    );      
  }

  static async addQuestionTranslation(question_id, language_id) {    
    return await this.POST(
      `/admin/questions/${question_id}/add-translation/${language_id}`        
    );          
  }

  static async updateQuestionTranslation( question_id, language_id, question_data ) {    
    return await this.POST(
      `/admin/questions/${question_id}/update-translation/${language_id}`, {question_data}        
    );          
  }
}
Base.extend(ExperimentService);

export default ExperimentService;
