import Base from "./baseClientService";
import { strFormat } from "../utils/functions";

const allCoursesIndex = "allcourses";
const coursePageIndex = "courses_perpage:%perpage%_page:%page%";
const oneCourseIndex = "course_%id%";
const categoriesTypeCachIndex = "course-types-categories";
const typesCachIndex = "course-types";

class CourseService extends Base {
  static async getCourseListPage(page = 1, perpage = 10) {
    // I opt for no caching here to decrease probability of data loss due to simultaneous record editing.
    try {
      // const items = await this.GET(
      //   `/admin/courses/all?page=${page}&perpage=${perpage}`
      // );
      return await this.GET(`/admin/courses/all?page=${page}&perpage=5000`);
    } catch (e) {
      console.error("Error fetching courses: ", e.message);
      return this.Error(e.message);
      // return false;
      // throw new Error("Error fetching couses:");
    }
  }

  static async getCourse(id) {
    console.debug("gettign course", id);
    try {
      const result = await this.GET("/admin/courses/" + id);
      console.debug("got course", id, result);
      return result;
    } catch (e) {
      console.error("Error fetching course: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getCourseTranslations(id) {
    console.debug("gettign course", id);
    try {
      const result = await this.GET(`/admin/courses/${id}/translations`);
      console.debug("got course", id, result);
      return result;
    } catch (e) {
      console.error("Error fetching course: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getCourseExperiments(id) {
    try {
      const result = await this.GET(`/admin/courses/${id}/experiments`);
      return result;
    } catch (e) {
      console.error("Error fetching course: ", e.message);
      return false;
    }
  }

  static async updateCourse(data) {
    const { course_id } = data;
    if (course_id) {
      try {
        const result = await this.POST("/admin/courses/upd", data);
        return result;
      } catch (e) {
        this.Error(e.message);
        return false;
      }
    } else {
      this.Error("Course ID not found in passed data");
      return false;
    }
  }

  static async addCourse(data) {
    try {
      await this.POST("/admin/courses/add", data);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }
  static async deleteCourse(id) {
    try {
      await this.POST(`/admin/courses/${parseInt(id)}/delete`);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async deleteFormulas(id) {
    try {
      await this.POST(`/admin/courses/${parseInt(id)}/formulas/delete`);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  /**
   * Uploads course image from form data
   */
  static async uploadImage(id, formData) {
    try {
      await this.POST(`/admin/courses/${parseInt(id)}/image`, formData);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  /**
   * Deletes course image
   * @param {*} id
   * @returns
   */
  static async deleteImage(id) {
    try {
      await this.POST(`/admin/courses/${parseInt(id)}/image/delete`);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async uploadFormulas(id, file) {
    try {
      await this.POST(`/admin/courses/${parseInt(id)}/formulas`, file);
      return true;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async getCourseRequirements(course_id) {
    try {
      return await this.GET(
        `/admin/courses/${parseInt(course_id)}/requirements`
      );
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async updateCourseRequirements(course_id, ids) {
    return await this.POST(
      `/admin/courses/${parseInt(course_id)}/requirements`,
      { ids }
    );
  }

  static async copyToLanguage(course_id, language_id) {
    return await this.POST(
      `/admin/courses/${parseInt(course_id)}/translations/create/${language_id}`
    );
  }

  static async getCourseTypes() {
    return this.getCachedOr(typesCachIndex, async () => {
      try {
        const result = await this.GET(`/admin/courses/content/course-type`);
        if (!Array.isArray(result))
          throw new Error("Wrong data received when loading course types");

        this.cache(typesCachIndex, result);
        return result;
      } catch (e) {
        return this.Error(e.message || "Unknown error");
      }
    });
  }
  static async getCourseTypesCategories() {
    const index = "course-types-categories";
    return this.getCachedOr(index, async () => {
      try {
        const result = await this.GET(`/admin/courses/content/course-type/categories`);
        if (!Array.isArray(result))
          throw new Error("Wrong data received when loading course types categories");

        this.cache(index, result);
        return result;
      } catch (e) {
        return this.Error(e.message || "Unknown error");
      }
    });
  }
  // at5000 functions

  // geting course categories
  static async getCourseCategories(course_id) {
    try {
      const result = await this.GET(
        `/admin/courses/content/${course_id}/categories`
      );
      //console.log("test getCourseCategories",result);
      return result;
    } catch (e) {
      console.error("Error fetching course categories: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // adding new course type
  static async addCourseType(data = {}) {
    try {
      const result = await this.POST(
        `/admin/courses/content/course-type/add`,
        data
      );
      this.clearCache(typesCachIndex);
      return result;
      //return 1;
    } catch (e) {
      console.error("Error adding new course type: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // update course type
  static async updateCourseType(type_id, data = {}) {
    try {
      const result =  await this.POST(
        `/admin/courses/content/course-type/${type_id}`,
        data
      );
      this.clearCache(typesCachIndex);
      return result;
      //return 1;
    } catch (e) {
      console.error("Error updating course type: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // adding new page to category
  static async addContentPage(course_id, category_id, data = {}) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/${category_id}/pages/add`,
        data
      );
      //return 1;
    } catch (e) {
      console.error("Error adding new page to category: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // deleting category for course
  static async deleteCategory(course_id, category_id) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/${category_id}/delete`
      );
      //return true;
    } catch (e) {
      console.error("Error deleting page: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // deleting page for category
  static async deletePage(course_id, category_id, page_id) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/${category_id}/pages/${page_id}/delete`
      );
      //return true;
    } catch (e) {
      console.error("Error deleting page: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // unassign content element for page in category
  static async unassignContentElement(
    course_id,
    category_id,
    page_id,
    element_id
  ) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/${category_id}/pages/${page_id}/elements/${element_id}/delete`
      );
      //return true;
    } catch (e) {
      console.error("Error deleting element: ", e.message);
      return this.Error(e.message);
    }
  }
  // get all content elements
  static async getAllContentElements() {
    try {
      return await this.GET(`/admin/courses/content/all`);
    } catch (e) {
      console.error("Error fetching all content elements: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // update content element data
  static async updateContentElement(element_id, data) {
    try {
      return await this.POST(`/admin/courses/content/${element_id}`, data);
    } catch (e) {
      console.error("Error updating content element: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // add new content element for page
  static async addContentElement(page_id, data) {
    try {
      return await this.POST(`/admin/courses/content/add/${page_id}`, data);
    } catch (e) {
      console.error("Error adding content element: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // assign content element for page
  static async assignContentElement(page_id, content_element_id) {
    try {
      return await this.POST(
        `/admin/courses/content/${content_element_id}/assign/${page_id}`
      );
    } catch (e) {
      console.error("Error asigning content element to page: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // add new category for course
  static async addCategory(course_id, data) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/add`,
        data
      );
    } catch (e) {
      console.error("Error adding category: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // add new category for types
  static async addTypeCategory(data) {
    try {
      const result = await this.POST(
        `/admin/courses/content/categories/add`,
        data
      );
      this.clearCache(categoriesTypeCachIndex);
      return result;
    } catch (e) {
      console.error("Error adding category: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  // update category for course
  static async updateCategory(course_id, cat_id, data) {
    try {
      return await this.POST(
        `/admin/courses/content/${course_id}/categories/${cat_id}`,
        data
      );
      //return true
    } catch (e) {
      console.error("Error adding category: ", e.message);
      return this.Error(e.message);
    }
  }
}
Base.extend(CourseService);

export default CourseService;
