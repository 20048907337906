import React from 'react'
import DistributorsService from '../../../services/distributors';
import Error from "../../template/Error";
import {Tabs, Tab} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import DistributorUsers from './distributor-users';
import Clients from './clients';

const  TabPanel = props => {
    const { children, value, id, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== id}
        id={`panel-${id}`}
        aria-labelledby={`tab-${id}`}
        {...other}
      >
        {value === id && (
          <>
            {children}
          </>
        )}
      </div>
    );
}
class Distrbutor extends React.Component{


    state={
        distributor:null,
        users:null,
        clients:null,
    }
    async componentDidMount() {
        const { id }=this.props.match.params ;
        const distributor = await DistributorsService.getOne(id);
        if(distributor){
            const {clients,users} = distributor;
            this.setState({distributor,clients,users});
        }
    }
    render(){
        const {distributor,users,clients} = this.state;
        const {section='properties', id} = this.props.match.params;
        if(!distributor){
            return <Error />
        }
        return(<>
            <Tabs value={section} centered className="m-bottom-10" >
                <Tab 
                    label="Properties" 
                    value={'properties'}
                    component={Link}
                    to={`/distributors/${id}`}
                    id={`properties`}
                    aria-controls={`properties`}
                />
                <Tab 
                    label="Users" 
                    value={'users'}
                    component={Link}
                    to={`/distributors/${id}/users`}
                    id={`users`}
                    aria-controls={`users`}
                />
                <Tab 
                    label="Clients" 
                    value={'clients'}
                    component={Link}
                    to={`/distributors/${id}/clients`}
                    id={`clients`}
                    aria-controls={`clients`}
                />
            </Tabs>
            <TabPanel value={section} id={"properties"}>
                properties
            </TabPanel>
            <TabPanel value={section} id={"users"}>
                <DistributorUsers {...{users,id}}/>
            </TabPanel>
            <TabPanel value={section} id={"clients"}>
                <Clients {...{clients,id}}/>
            </TabPanel>
        </>)
    }
}

export default Distrbutor